import { config } from '../config/config.mjs'
import { store } from '../store'

export function wsConnect() {
  console.log("called wsConnect")
  return new Promise((resolve,reject) =>{
  let ws = new WebSocket(`${config.ws.host}`)
  ws.onopen = () => {    
    store.commit('setWs', ws)
    store.commit('wsConnected',true)
    console.log('Connected')
    resolve(ws)
  }
  ws.onmessage = message => {
    let json = JSON.parse(message.data)
    let event = json.event
    let data = json.data
    console.log('incoming')
    console.log(event)
    if (event === 'setRecent') {
      let recent = []
      for (let order of data) {
        for (let item of order.items) recent.push(item.itemNumber)
      }
      store.commit('setRecent', recent)
      console.log('Recent set')
    }
    else if (event === "insertOrder" || event === "insertQuote"){
      if(Object.keys(store.state.selectedClient).length==0||store.state.selectedClient.clientNumber==data.clientNumber){
        let sound = new Audio('/img/nieuw.mp3')
        let promise = sound.play()
        if (promise !== undefined) {
          promise.then(() => {
          }).catch(error => {
            console.error(error)
          })
        store.commit(event, data)
      }}
    }
    else if (event) {
      store.commit(event, data)
    }

  }
  ws.onclose = e => {
    store.commit('wsConnected',false)
    if(store.state.loggedIn){
      console.log(
        'Socket is closed. Reconnect will be attempted in 5 seconds.',
        e.reason
      )
    setTimeout(async () => {
      try {
        await wsConnect()
      } catch (e) {
        console.error(e)
      }     
    }, 5000)
  }
    reject(e)
  }
  ws.onerror = (e) => {
    console.error('Socket encountered error: ', e, 'Closing socket')
    ws.close()
    reject(e)
  };
})}
