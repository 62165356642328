import { config } from "../config/config"
import { store } from '../store'
export function groupBy(prop, arr) {
  return this.reduce((categories, item) => {
    var cat = item[prop]
    if (arr.includes(item.itemNumber)) {
      categories['Recent'] = categories['Recent'] || []
      categories['Recent'].push(item)
    }
    categories[cat] = categories[cat] || []
    categories[cat].push(item)
    return categories
  }
    , {})
}
export function infoHandler(message,action,actionText,timeout){
  store.commit("snack", {
    snackBar: true,
    info: true,
    snackText: message,
    action: action,
    actionText: actionText,
    timeout: timeout || 3000
  })
}
export function errorHandler(e,message,timeout){
  console.error(e)
  store.commit("snack", {
    snackBar: true,
    error: true,
    snackText: message,
    timeout: timeout || 3000
  })
}
export function successHandler(message,timeout){
  store.commit("snack", {
    snackBar: true,
    success: true,
    snackText: message,
    timeout: timeout || 1000
  })
}
export function toDouble(val) {
  val = typeof val ==='string'? parseFloat(val):val
  let isInt = val % 1 === 0
  if(isInt)return val.toFixed(0)
  return val.toFixed(2).replace('.', ',')
}
export function toCurrency(val) {
  val = typeof val ==='string'? parseFloat(val):val
  return val.toLocaleString("nl-NL", {
    style: "currency",
    currency: "EUR"
  })
}
export function toCurrencyRounded(val) {
  val = typeof val ==='string'? Math.round(parseFloat(val)):Math.round(val)
  return val.toLocaleString("nl-NL", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
}
export function toDateString(val) {
  let date = new Date(val)
  return date.toLocaleDateString("nl-NL")
}
export function toTimeString(val) {
  let date = new Date(val)
  return date.toLocaleTimeString("nl-NL", {hour: '2-digit', minute:'2-digit'})
}
export function toDateTimeString(val) {
  let date = new Date(val)
  return date.toLocaleString("nl-NL")
}
export function getWeekNumber(date) {
  var d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  var dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
}
export async function fetchGET(event, data) {
  try{
  store.commit("dataLoaded", false)
  data = JSON.stringify(data)
  let response = await fetch(`${config.api.host}/${event}/${data}`, { method: 'GET', headers: { 'Content-Type': 'application/json' },credentials:"include" })
  if (response.status == 401 && store.state.loggedIn) {
    store.dispatch("logout")
    return
  }
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const reader = response.body.getReader()
  const contentLength = +response.headers.get('Content-Length')
  let receivedLength = 0
  let chunks = []
  let busy = true
  while (busy) {
    const { done, value } = await reader.read()
    if (done) {
      busy = false
      setTimeout(() => {
        store.commit("dataLoaded", true)
      }, 500);
      break;
    }
    chunks.push(value)
    receivedLength += value.length
    let percentage = receivedLength / contentLength * 100
    store.commit("setProgress", percentage)
  }
  let chunksAll = new Uint8Array(receivedLength)
  let position = 0
  for (let chunk of chunks) {
    chunksAll.set(chunk, position)
    position += chunk.length
  }
  let result = new TextDecoder("utf-8").decode(chunksAll)
  let commits = JSON.parse(result)
  return commits
}
catch(error){
  console.log(error)
  store.commit("dataLoaded", true)
  return Promise.reject(error)
}
}
export async function fetchPOST(event, data) {
  try{
  store.commit("dataLoaded", false)
  data = {...data, wsId: store.state.wsId}
  data = JSON.stringify(data)
  store.commit("setProgress", 50)
  let response = await fetch(`${config.api.host}/${event}`, { method: 'POST', body: data, headers: { 'Content-Type': 'application/json' },credentials:"include" })
  store.commit("dataLoaded", true)
  return response.json()
  }
  catch(error){
  console.error(error)
  store.commit("dataLoaded", true)
  return Promise.reject(error)
  }
}
export async function sendMessage(event, data) {
  try{
  store.commit("dataLoaded", false)
  data = JSON.stringify(data)
  store.commit("setProgress", 50)
  let response = await fetch(`${config.api.host}/message/${event}`, { method: 'POST', body: data, headers: { 'Content-Type': 'application/json' },credentials:"include" })
  store.commit("dataLoaded", true)
  return response.json()
  }
  catch(error){
  console.error(error)
  store.commit("dataLoaded", true)
  return Promise.reject(error)
  }
}
export function createOrder(obj,print,email) {
  let items= obj.items
  let d = new Date()
  let day = d.getDate()
  let month = d.getMonth() + 1
  let week = getWeekNumber(d)
  let year = d.getFullYear()
  let quarter = Math.ceil(month / 3)
  let date = new Date()
  let netTotal = items.reduce((acc, cur) => {
    return (acc*100 + cur.netSubtotal*100)/100
  }, 0)
  let totalVAT = items.reduce((acc, cur) => {
    return (acc*100 + cur.totalVAT*100)/100
  }, 0)
  let grossTotal = items.reduce((acc, cur) => {
    return (acc*100 + cur.grossSubtotal*100)/100
  }, 0)
  let orderVAT 
  if(obj.client.export){
    orderVAT = [{name: "Geen", amount: 0, total: netTotal}]
  }
  else{ orderVAT = JSON.parse(JSON.stringify(store.state.settings.VAT))
      for (let VAT of orderVAT) {
      VAT.total = items.filter(item=> item.VAT==VAT.amount).reduce((acc,cur2)=> (acc*100 + cur2.totalVAT*100)/100,0 )
    }
  }
  let status=0
  if(print||email)status=1
  let VAT = orderVAT.filter(VAT => parseFloat(VAT.total) != 0)
  let order = {
    client: obj.client,
    name: obj.client.name,
    clientNumber: obj.client.clientNumber,
    quoteNumber: obj.quoteNumber? obj.quoteNumber: undefined,
    items: obj.items,
    date: obj.deliveryDate? obj.deliveryDate: date.toISOString().split("T")[0],
    deliveryDate: obj.deliveryDate ? obj.deliveryDate : undefined,
    deliveryTime: obj.deliveryTime? obj.deliveryTime : undefined,
    day: day,
    week: week,
    month: month,
    year: year,
    quarter: quarter,
    netTotal: netTotal,
    totalVAT: totalVAT,
    grossTotal: grossTotal,
    credit: 0,
    debit: 0,
    payments: [],
    totalPaid: 0,
    VAT: VAT,
    status: status,
    printed: print?true:false,
    emailed: email?true:false,
    expand: false,
    type: "sales",
    notes: obj.notes? obj.notes: undefined,
    request: obj.request? obj.request: undefined,
    totalProfit: 0,
    totalCost: 0,
    discount: 0,
    totalDiscount: 0,
    totalPackages: obj.totalPackages
  }
  return order
}
export function createInvoice(obj,print,email) {
  let orders= obj.orders
  let d = new Date()
  let day = d.getDate()
  let month = d.getMonth() + 1
  let week = getWeekNumber(d)
  let year = d.getFullYear()
  let quarter = Math.ceil(month / 3)
  let date = new Date()
  let payments = orders
    .map(order => order.payments)
    .filter(payment => payment.length > 0)
    .flat()
  let totalPaid = payments.length
    ? payments.reduce((acc, cur) => (acc*100 + cur.amount*100)/100, 0)
    : 0
  let credit = totalPaid
  let payTerms = obj.client.payTerms >= 0 ? obj.client.payTerms : store.state.settings.payTerms
  d.setDate(date.getDate() + payTerms)
  let dueDate = new Date(d.toISOString().split("T")[0])
  let netTotal = orders.reduce((acc, cur) => {
    return (acc*100 + cur.netTotal*100)/100
  }, 0)
  let totalVAT = orders.reduce((acc, cur) => {
    return (acc*100 + cur.totalVAT*100)/100
  }, 0)
  let grossTotal = orders.reduce((acc, cur) => {
    return (acc*100 + cur.grossTotal*100)/100
  }, 0)
  let invoiceVAT 
  if(obj.client.export){
    invoiceVAT = [{name: "Geen", amount: 0, total: netTotal}]
  }
  else{ invoiceVAT = JSON.parse(JSON.stringify(store.state.settings.VAT))
      for (let VAT of invoiceVAT) {
      VAT.total = orders.reduce((acc,cur)=>{
        return (acc*100 + cur.VAT.filter(vat=> vat.amount==VAT.amount).reduce((acc,cur2)=> (acc*100 + cur2.total*100)/100,0 )*100)/100
      },0)
    }
  }
  let debit = (grossTotal*100 - credit*100)/100
  let status=0
  if(print||email)status=1
  if(debit==0)status=2
  let VAT = invoiceVAT.filter(VAT => parseFloat(VAT.total) != 0)
  let invoice = {
    client: obj.client,
    name: obj.client.name,
    clientNumber: obj.client.clientNumber,
    orders: obj.orders,
    date: date.toISOString().split("T")[0],
    dueDate: dueDate,
    day: day,
    week: week,
    month: month,
    year: year,
    quarter: quarter,
    netTotal: netTotal,
    totalVAT: totalVAT,
    grossTotal: grossTotal,
    credit: credit,
    debit: debit,
    payments: payments,
    totalPaid: totalPaid,
    VAT: VAT,
    status: status,
    printed: print?true:false,
    emailed: email?true:false,
    expand: false,
    type: "sales",
    notes: "",
    totalProfit: 0,
    totalCost: 0,
    discount: 0,
    totalDiscount: 0
  }
  return invoice
}
export async function insertInvoice(data) {
  try {
      let res = await fetchGET("invoiceNumber",null)
      data.invoiceNumber = res
      let orders = data.orders
      orders.forEach(order => {
          order.invoiceNumber = data.invoiceNumber
          order.selectable = false
          order.status = 3
      })
      let response = await fetchPOST("insertInvoice",data)        
      let invoice = response[0].value.ops[0]
      return invoice
  }
  catch (e) {
    errorHandler(e,"Factuur niet opgeslagen")
  }
}